@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #141414;
  overflow: hidden;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #e45a84;
  top: 31%;
  left: 51%;
  animation-duration: 6s;
  animation-delay: -23s;
  transform-origin: 8vw -24vh;
  box-shadow: -40vmin 0 5.381613228453735vmin currentColor;
}
.background span:nth-child(1) {
  color: #e45a84;
  top: 87%;
  left: 3%;
  animation-duration: 51s;
  animation-delay: -39s;
  transform-origin: 19vw -10vh;
  box-shadow: 40vmin 0 5.115045254738442vmin currentColor;
}
.background span:nth-child(2) {
  color: #e45a84;
  top: 86%;
  left: 75%;
  animation-duration: 48s;
  animation-delay: -45s;
  transform-origin: 24vw -2vh;
  box-shadow: -40vmin 0 5.75885716353784vmin currentColor;
}
.background span:nth-child(3) {
  color: #583c87;
  top: 27%;
  left: 2%;
  animation-duration: 8s;
  animation-delay: -34s;
  transform-origin: 7vw -13vh;
  box-shadow: -40vmin 0 5.233162359864987vmin currentColor;
}
.background span:nth-child(4) {
  color: #e45a84;
  top: 47%;
  left: 38%;
  animation-duration: 52s;
  animation-delay: -15s;
  transform-origin: 19vw -2vh;
  box-shadow: -40vmin 0 5.29365597974065vmin currentColor;
}
.background span:nth-child(5) {
  color: #583c87;
  top: 24%;
  left: 46%;
  animation-duration: 25s;
  animation-delay: -6s;
  transform-origin: -7vw -4vh;
  box-shadow: -40vmin 0 5.737975419434687vmin currentColor;
}
.background span:nth-child(6) {
  color: #583c87;
  top: 15%;
  left: 12%;
  animation-duration: 33s;
  animation-delay: -47s;
  transform-origin: -18vw 4vh;
  box-shadow: 40vmin 0 5.917831826664312vmin currentColor;
}
.background span:nth-child(7) {
  color: #583c87;
  top: 73%;
  left: 26%;
  animation-duration: 27s;
  animation-delay: -37s;
  transform-origin: -17vw 2vh;
  box-shadow: 40vmin 0 5.502933757236079vmin currentColor;
}
.background span:nth-child(8) {
  color: #e45a84;
  top: 74%;
  left: 39%;
  animation-duration: 46s;
  animation-delay: -12s;
  transform-origin: 23vw -22vh;
  box-shadow: -40vmin 0 5.90819860450341vmin currentColor;
}
.background span:nth-child(9) {
  color: #ffacac;
  top: 53%;
  left: 71%;
  animation-duration: 24s;
  animation-delay: -48s;
  transform-origin: 25vw 16vh;
  box-shadow: 40vmin 0 5.876088479480225vmin currentColor;
}
.background span:nth-child(10) {
  color: #ffacac;
  top: 68%;
  left: 96%;
  animation-duration: 32s;
  animation-delay: -28s;
  transform-origin: 23vw 7vh;
  box-shadow: -40vmin 0 5.029766847004051vmin currentColor;
}
.background span:nth-child(11) {
  color: #ffacac;
  top: 41%;
  left: 44%;
  animation-duration: 32s;
  animation-delay: -37s;
  transform-origin: -3vw -21vh;
  box-shadow: -40vmin 0 5.671735860848223vmin currentColor;
}
.background span:nth-child(12) {
  color: #e45a84;
  top: 27%;
  left: 76%;
  animation-duration: 24s;
  animation-delay: -37s;
  transform-origin: 23vw 5vh;
  box-shadow: 40vmin 0 5.398631217594328vmin currentColor;
}
.background span:nth-child(13) {
  color: #ffacac;
  top: 86%;
  left: 49%;
  animation-duration: 22s;
  animation-delay: -21s;
  transform-origin: 14vw 10vh;
  box-shadow: 40vmin 0 5.6173121837790845vmin currentColor;
}
.background span:nth-child(14) {
  color: #583c87;
  top: 3%;
  left: 84%;
  animation-duration: 37s;
  animation-delay: -47s;
  transform-origin: 15vw -1vh;
  box-shadow: 40vmin 0 5.022380435996967vmin currentColor;
}
.background span:nth-child(15) {
  color: #583c87;
  top: 66%;
  left: 100%;
  animation-duration: 28s;
  animation-delay: -14s;
  transform-origin: -4vw 21vh;
  box-shadow: 40vmin 0 5.440777897644844vmin currentColor;
}
.background span:nth-child(16) {
  color: #583c87;
  top: 44%;
  left: 30%;
  animation-duration: 12s;
  animation-delay: -31s;
  transform-origin: 12vw -14vh;
  box-shadow: -40vmin 0 5.511206633632798vmin currentColor;
}
.background span:nth-child(17) {
  color: #583c87;
  top: 74%;
  left: 53%;
  animation-duration: 16s;
  animation-delay: -5s;
  transform-origin: -2vw 25vh;
  box-shadow: -40vmin 0 5.719256188545416vmin currentColor;
}
.background span:nth-child(18) {
  color: #ffacac;
  top: 90%;
  left: 77%;
  animation-duration: 41s;
  animation-delay: -3s;
  transform-origin: 22vw 19vh;
  box-shadow: 40vmin 0 5.488463796925618vmin currentColor;
}
.background span:nth-child(19) {
  color: #e45a84;
  top: 4%;
  left: 29%;
  animation-duration: 41s;
  animation-delay: -7s;
  transform-origin: 22vw -6vh;
  box-shadow: 40vmin 0 5.101732691059473vmin currentColor;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.App {
  text-align: center;
  float: left;
}

#logo {
  display: block;
  border-style: hidden;
  border-radius: 25%;
  width: 5vw;
  margin: auto;
  position: fixed;
  left: 105%;
  transform: translate(-50%, -50%);
}

#cat {
  display: block;
  width: 25vw;
}

#discord {
  display: inline;
  width: 8vw;
  height: 8vw;
  padding: 1vw 1vw 1vw 1vw;
  margin-right: 4vw;
  background-color: #5865F2;
  border-radius: 2vw;
  transition: 0.3s;
}

#discord:hover {
  outline: white solid;
}

#vk {
  display: inline;
  width: 8vw;
  height: 8vw;
  margin-left: 4vw;
  transition: 0.3s;
  border-style: hidden;
  border-radius: 2vw;
}

#vk:hover {
  outline: white solid;
}

span {
  text-align: left;
  float: left;
  width: 50vw;
  margin-left: 5vw;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight:900;
  font-size: 2vw;
  margin-bottom: 5vw;
}

button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  background-color: rgb(var(--pure-material-primary-rgb, 246, 190, 0));
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 900;
  /* line-height: 36px; */
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
  margin-top: 10px;
}

button::-moz-focus-inner {
  border: none;
}

/* Overlay */
button::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
button::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px; /* Safari */
  height: 32px; /* Safari */
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
button:hover,
button:focus {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

button:hover::before {
  opacity: 0.08;
}

button:focus::before {
  opacity: 0.24;
}

button:hover:focus::before {
  opacity: 0.3;
}

/* Active */
button:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

button:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
button:disabled {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
  box-shadow: none;
  cursor: initial;
}

button:disabled::before {
  opacity: 0;
}

button:disabled::after {
  opacity: 0;
}
